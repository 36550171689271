<template>
  <v-menu
      max-width="25%"
  >
    <template v-slot:activator="{ on, attrs }">

      <v-btn v-bind="attrs" v-on="on" text>
        <v-icon
            v-bind="attrs"
            v-on="on"
            v-if="value"
            :color="kebabize(getColor)"
        >
          {{ value }}
        </v-icon>
        <template v-else>Aucune icône</template>
      </v-btn>

    </template>

    <v-card class="pa-4 text-center" width="360">

      <v-btn icon @click="$emit('input', null)" color="danger" title="Retirer l'icône">
        <v-icon color="red">mdi-close-circle</v-icon>
      </v-btn>

      <template v-for="(iconCategory, i) in icons">
        <div :key="'icon-category-' + i" class="mt-2">
          <span class="subtitle-2">{{ iconCategory.name }}</span>
          <br>
          <template v-for="(icon, j) in iconCategory.icons">
            <v-btn
                :key="'picker-icon'+j"
                @click="$emit('input', icon)"
                icon
                :title="icon.replace('mdi-', '')"
            >
              <v-icon>
                {{ icon }}
              </v-icon>
            </v-btn>
          </template>
        </div>
      </template>
    </v-card>

  </v-menu>
</template>

<script>
export default {
  name: "IconPicker",
  props: {
    value: String,
    color: String
  },
  computed: {
    getColor() {
      return this.color || ""
    }
  },
  data() {
    return {
      icons: [
        {
          name: "Informatif",
          icons: [
            "mdi-flag",
            "mdi-alert",
            "mdi-account",
            "mdi-account-alert",
            "mdi-check",
            "mdi-content-save",
            "mdi-send",
            "mdi-cog",
            "mdi-cogs",
            "mdi-information",
            "mdi-delete",
            "mdi-bell",
            "mdi-eye",
            "mdi-hammer-wrench",
            "mdi-sign-caution",
            "mdi-microsoft-internet-explorer",
          ]
        },
        {
          name: "Réseau",
          icons: [
            "mdi-message-alert",
            "mdi-message-bulleted",
            "mdi-message-bulleted-off",
            "mdi-email",
            "mdi-message-reply-text",
            "mdi-earth",
            "mdi-earth-off",
            "mdi-cellphone",
            "mdi-cellphone-link",
            "mdi-cellphone-link-off",
            "mdi-cloud",
            "mdi-cloud-alert",
            "mdi-antenna",
            "mdi-signal",
            "mdi-signal-variant",
            "mdi-web",
            "mdi-wifi",
            "mdi-wifi-off"
          ]
        },
        {
          name: "Emoticons",
          icons: [
            "mdi-emoticon",
            "mdi-emoticon-angry",
            "mdi-emoticon-confused",
            "mdi-emoticon-cry",
            "mdi-emoticon-dead",
            "mdi-emoticon-frown",
            "mdi-emoticon-happy",
            "mdi-emoticon-neutral",
            "mdi-emoticon-wink",
          ]
        },
        {
          name: "Organisation",
          icons: [
            "mdi-puzzle",
            "mdi-table",
            "mdi-apps",
            "mdi-collage",
            "mdi-folder-open",
            "mdi-book-open",
            "mdi-book-open-blank-variant",
            "mdi-desktop-mac-dashboard",
            "mdi-cube-outline",
          ]
        },
        {
          name: "Indicateurs",
          icons: [
            "mdi-format-quote-open",
            "mdi-star",
            "mdi-heart",
            "mdi-charity",
            "mdi-fire",
            "mdi-snowflake",
            "mdi-chart-line",
            "mdi-solar-power",
            "mdi-lightbulb-multiple",
          ]
        },
        {
          name: "Activité",
          icons: [
            "mdi-alarm",
            "mdi-music",
            "mdi-calendar",
            "mdi-camera",
            "mdi-arm-flex",
            "mdi-cake-variant",
            "mdi-cake-layered",
            "mdi-cake",
            "mdi-car",
            "mdi-home",
            "mdi-tree",
            "mdi-brain",
            "mdi-briefcase",
            "mdi-briefcase-variant",
            "mdi-coffee-outline",
            "mdi-target",
            "mdi-bullseye-arrow",
            "mdi-cash",
          ]
        },
        {
          name: "Divers",
          icons: [
            "mdi-atom",
            "mdi-stamper",
            "mdi-frequently-asked-questions",
            "mdi-swap-vertical-bold",
            "mdi-palette-swatch",
            "mdi-tag",
            "mdi-google-translate",
            "mdi-ticket-percent-outline",
            "mdi-trophy",
            "mdi-truck",
            "mdi-printer",
            "mdi-pumpkin",
            "mdi-spider-thread",
            "mdi-fingerprint",
            "mdi-key-variant",
            "mdi-bug",
            "mdi-resistor",
            "mdi-rocket",
            "mdi-rocket-launch",
            "mdi-lifebuoy",
            "mdi-routes",
            "mdi-data-matrix",
            "mdi-medal",
            "mdi-share",
            "mdi-share-variant-outline",
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>